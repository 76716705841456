.home-insta {
  $gradientOverText: linear-gradient(90deg, rgba(255, 255, 255, .4) 0%, rgba(255, 255, 255, 0) 50%);
  font-size: 0;
  padding-bottom: 100px;
    article {
      @include max-screen($m-screen) {
        clear: both;
      }      
    }

  
  .home-insta__tile {
    display: inline-block;
    width: 25%;
    vertical-align: top;
    background: #806a8c;
    position: relative;   
    &--large {
      width: 50%;
      display: inline-block;
      vertical-align: bottom;
      position: relative;
    }
    
    .heart-icon {
      width: 33px;
      height: 25px;
      background: url('../images/heart-icon.png') center center no-repeat;
      display: block;
      position: absolute;
      bottom: 20px;
      left: 20px;
      transition: $ease-back;
      
      &:hover {
        transform: scale(1.2);
      }
    }
    
    img {
      display: block;
      width: 100%;
      opacity: .3;
    }
  }
  
  .icons {
    float: right;
    margin-top: 100px;
    margin-right: 20%;
    
    .icon {
      transition: $ease-normal;
      display: inline-block;
      margin-left: 20px;
      
      &:hover {
        opacity: .7;
      }
      
      img {
        display: block;
      }
    }
  }
  
  h1 {
    font: 42px/42px 'Open Sans', sans-serif;
    color: #3a1662;
    text-transform: uppercase;
    display: inline-block;
    font-weight: bold;
    position: relative;
    margin-left: 30px;
    margin-bottom: 70px;
    
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: $gradientOverText;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
  
  .full-line {
    &.animated {
      width: 80%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  
  .home-insta__block {
    width: 40%;
    display: inline-block;
    vertical-align: top;
    margin-top: 60px;
    margin-right: 10%;
    
    p {
      font: 14px/24px 'Roboto', sans-serif;
      color: #898989;
    }
  }
}