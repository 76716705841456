
%gradient-title {
  font: 42px/42px 'Open Sans', sans-serif;
  color: #3e1c65;
  display: block;
  font-weight: bold;
  position: relative;
  margin: 0;
  text-transform: uppercase;
  
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: $gradientOverText;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  
  @include max-screen($m-screen) {
    width: 80% !important;
    display: block !important;
    margin: 0 auto !important;
  }
}