.home-essence {
  padding-bottom: 100px;
  
  padding-top: 100px;
  
  .container {
    position: relative;
    overflow: hidden;
  }
  
  .home-essence__block {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-top: 40px;
    font-size: 0;
    
    @include max-screen($m-screen) {
      width: 100% !important;
      display: block;
    }
    
    &:nth-child(2) {
      width: 390px;
      
      @include max-screen(1080px) {
        width: 40%;
      }
    }
    
    &:nth-child(3) {
      width: 565px;
      
      @include max-screen(1080px) {
        width: 59%;
      }
    }
  }
  
  h1 {
    @extend %gradient-title;
  }
  
  .home-essence__text-cell {
    width: 220px;
    display: inline-block;
    vertical-align: top;
    
    &:nth-child(2n+1) {
      margin-right: 100px;
      @include max-screen(1080px) {
        margin-right: 19%;
      }
    }
    
    @include max-screen(1080px) {
      width: 40%;
    }
    
    @include max-screen($m-screen) {
      width: 80% !important;
      display: block !important;
      margin: 0 auto !important;
    }
    
    h2 {
      font: 18px 'Open Sans', sans-serif;
      color: #3e1c65;
      position: relative;
      display: block;
      font-weight: 800;
      
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        background: $gradientOverText;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
      }
    }
    
    p {
      font: 16px/25px 'Roboto', sans-serif;
      font-weight: 100;
    }
  }
}