.home-blog {
  background: url('../images/bg5.jpg') center center no-repeat;
  background-size: cover;
  padding-bottom: 100px;
  text-align: center;
  
  .logos {
    margin-bottom: 70px;
  }
  
  .short-line {
    margin: 20px auto;
  }
  
  .slide-btn {
    margin-top: 70px;
  }
  
  .home-blog__post {
    width: 30%;
    display: inline-block;
    text-align: left;
    position: relative;
    
    &:nth-child(2) {
      margin: 0 3%;
    }
    
    img {
      width: 100%;
      display: block;
    }

    .date {
      width: 69px;
      height: 89px;
      background-color: #fcff00;
      font: 35px 'Open Sans', sans-serif;
      font-weight: 800;
      float: right;
      text-align: center;
      padding-top: 5px;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      right: 0;

      span {
        display: block;
        font-size: 20px;
        text-transform: uppercase;
        line-height: 20px;
      }
    }
    
    .home-blog__post-infos {
      padding: 30px;
      border: 2px solid $highlight-color;
      border-top: 0;
      
      p {
        font: 15px/20px 'Open Sans', sans-serif;
        font-style: italic;
        color: #000;
      }
      
      h1 {
        font: 16px 'Open Sans', sans-serif;
        font-weight: 800;
        text-transform: uppercase;
        color: #000;
        display: block;
        position: relative;
        
        &:after {
          content: '';
          width: 100%;
          height: 2px;
          background: $highlight-color;
          position: absolute;
          top: -25px;
          left: 0;
        }
        
        &:before {
          content: '';
          position: absolute;
          top: -25px;
          left: 10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-color: $highlight-color transparent transparent transparent;
        }
      }
    }
  }
}