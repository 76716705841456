.portfolio-block {
  background: url('../images/cases-portfolio-bg.jpg') center center fixed;
  background-size: cover;
  padding: 100px 0;
  text-align: center;
  
  h1 {
    font: 42px 'Open Sans', sans-serif;
    font-weight: 300;
    color: #000;
    margin: 0;
    padding: 0 10px;
    display: inline;
    background: $highlight-color;
    
    @include max-screen($m-screen) {
      font-size: 25px;
      line-height: 45px;
    }
  }
  
  h2 {
    font: 26px 'Open Sans', sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    display: block;
    margin: 30px 0;
    
    @include max-screen($m-screen) {
      font-size: 18px;
      line-height: 28px;
    }
  }
}