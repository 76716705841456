.results-block {
  background: url('../images/cases-resultados-bg.jpg') center center no-repeat fixed;
  background-size: cover;
  text-align: center;
  padding: 60px 0;
  
  @include max-screen($m-screen) {
    background-position: right center;
  }
  
  h1 {
    font: 46px 'Open Sans', sans-serif;
    color: #fff;
    display: block;
    margin: 0;
    
    @include max-screen($m-screen) {
      font-size: 25px;
      line-height: 35px;
    }
  }
  
  h2 {
    font: 26px 'Open Sans', sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    display: block;
    margin-top: 30px;
    
    @include max-screen($m-screen) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  
  .slide-btn {
    margin-top: 40px;
    margin-bottom: 50px;
  }
  
  img {
    display: block;
    margin: 0 auto;
  }
}