.slide-btn {
  font: 16px 'Roboto', sans-serif;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid $highlight-color;
  color: #fff;
  font-weight: bold;
  background: #000;
  min-width: 200px;
  text-align: center;
  padding: 15px 20px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  transform: translate3D(0, 0, 0);
  
  &:after,
  .hover {
    content: '';
    width: 150%;
    height: 100%;
    background: $highlight-color;
    position: absolute;
    top: 0;
    left: -180%;
    transform: skewX(-45deg) translate3d(0, 0, 0);
    transition: $ease-inout;
  }
  
  &:hover {
    &:after,
    .hover {
      left: -15%;
    }
    
    span {
      color: #000;
    }
  }
  
  span {
    position: relative;
    z-index: 1;
    transition: $ease-inout;
  }
}
