.zorzo-our-process {
  text-align: center;
  background: #34006e url('../images/bg_processo.jpg') top center no-repeat;
  background-size: contain;
  padding-top: 260px;
  
  h1 {
    font: 30px 'Open Sans', sans-serif;
    color: $highlight-color;
    font-weight: 800;
    text-transform: uppercase;
    margin: 0;
  }
    
  h2 {
    margin: 0;
    font: 25px 'Roboto Condensed', sans-serif;
    color: #fff;
    font-weight: 100;
    font-style: oblique;
    margin-bottom: 70px;
  }
  
  img {
    margin-bottom: 100px;
  }
  
}