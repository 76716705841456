$ui-color: #fff;
$font-color: #000;
$font-family: 'Open Sans', sans-serif;
$highlight-color: #fcff00;

$ease-normal: all 200ms ease;
$ease-back: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-inout: all 600ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

$xl-screen: 1024px;
$l-screen: 960px;
$m-screen: 768px;
$s-screen: 480px;
$xs-screen: 320px;

$gradientOverText: linear-gradient(90deg, rgba(255, 255, 255, .4) 0%, rgba(255, 255, 255, 0) 50%);
