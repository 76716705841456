.zorzo-team {
  font-size: 0;
  
  .container {
    @include max-screen($m-screen) {
      width: 100%;
    }
  }
  
  .zorzo-team__block {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 50px;
    padding-left: 0;
    border-top: 3px solid $highlight-color;
    
    @include max-screen($m-screen) {
      width: 90%;
      display: block;
      margin: 0 auto;
      padding: 40px 25px !important;
    }
    
    .gradient-title {
      @extend %gradient-title;
      
      @include max-screen($m-screen) {
        margin-left: 0 !important;
      }
    }
    
    p {
      font-size: 14px;
      line-height: 27px;
      color: #898989;
      margin-top: 30px;
    }
    
    &--highlighted {
      @extend .zorzo-team__block;
      background: $highlight-color;
      padding: 50px 70px;
      position: relative;
      
      @include max-screen($m-screen) {
        width: 100%;
        display: block;
        padding: 40px 25px;
        padding-bottom: 0 !important;
      }
      
      h1 {
        font-size: 26px;
        line-height: 31px;
        margin: 0;
        font-weight: 800;
        text-transform: uppercase;
      }
      
      h2 {
        font-size: 13px;
        margin: 30px 0;
      }
      
      p {
        line-height: 20px;
        font-size: 12px;
        color: #000;
      }
      
      img {
        position: absolute;
        right: -35%;
        bottom: 0;
        display: block;
        
        @include max-screen($m-screen) {
          position: relative;
          right: auto;
        }
      }
    }
  }
  
  .hero {
    position: relative;
    display: inline-block;
    margin-top: 40px;
    
    &:after {
      content: '';
      width: 80%;
      height: 0;
      padding-bottom: 80%;
      background: rgba(0, 0, 0, .9);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      border-radius: 50%;
      z-index: 8;
      background: radial-gradient(ellipse at center,  rgba(255, 255, 255, .4) 0%, rgba(255, 255, 255, 0) 100%);
    }
    
    h3 {
      font: 75px/75px 'Roboto', sans-serif;
      font-weight: 100;
      margin: 0;
      text-transform: uppercase;  
      display: block;
      position: relative;
      z-index: 1;
      color: #aeaaa7;
      
      @include max-screen($m-screen) {
        font-size: 60px;
        line-height: 60px;
      }
      
      &:nth-child(2) {
        text-align: center;
      }
      
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
}