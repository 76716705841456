.portfolio-slider-full {
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  padding-top: 0;
  
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    transition: $ease-inout;
    
    &.current {
      transform: translate3D(0, 0, 0);      
    }
    
    &.next {
      transform: translate3D(100%, 0, 0);
    }
    
    &.prev {
      transform: translate3D(-100%, 0, 0);
    }
  }
  
  .portfolio-description {
    position: absolute;
    width: 80%;
    max-width: 450px;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, .8);
    padding: 50px;
    z-index: 2;
    display: inline-block;
    transition: $ease-inout;
    transform: translate3D(100%, 0, 0);
    
    &.open {
      transform: translate3D(0, 0, 0);
    }
    
    .toggle {
      width: 205px;
      height: 115px;
      background: $highlight-color;
      display: block;
      position: absolute;
      right: 100%;
      top: 0;
      cursor: pointer;
      
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 0 14px 13px;
        border-color: transparent transparent transparent $highlight-color;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 100%;
      }
      
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;        
      }
    }
    
    h1 {
      font: 26px/32px 'Open Sans', sans-serif;
      color: #fff;
      text-transform: uppercase;
      font-weight: 900;
      margin: 0;
    }
    
    h2 {
      font: 14px/18px 'Roboto', sans-serif;
      color: #fff;
      font-weight: 900;
      margin: 0;
      margin-top: 20px;
      display: block;
    }
    
    p {
      font: 13px/27px 'Roboto', sans-serif;
      color: #fff;
      display: block;
      margin-top: 30px;
    }
    
    .infos {
      margin-top: 30px;
      display: block;
    }
    
    h3 {
      font: 13px 'Open Sans', sans-serif;
      color: #fff;
      display: block;
      text-transform: uppercase;
      margin: 0;
      font-weight: 900;
      margin: 15px 0;
    }
    
    .mark {
      font: 12px 'Open Sans', sans-serif;
      display: block;
      color: #fff;
      font-weight: 600;
      
      &:before {
        content: '+';
        display: inline-block;
        font: 12px 'Open Sans', sans-serif;
        color: $highlight-color;
        margin-right: 5px;
        font-weight: 500;
      }
    }
  }
}

.portfolio-slider-full-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  
  .container {
    background: rgba(0, 0, 0, .5);
    padding: 30px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  
  .categories-suggestions {
    top: auto;
    bottom: 100%;
    left: 0;
    transform: translate3D(0, -20px, 0);
    
    &.open {
      transform: translate3D(0, -1px, 0);
    }
    
    .actions {
      margin-top: 0;
    }
  }
  
  .slider-controls {
    display: inline-block;
    vertical-align: middle;
  }
  
  .control-btn {
    display: inline-block;
    vertical-align: middle;
  }
  
  .slide-numbers {
    display: inline-block;
    vertical-align: middle;
    margin: 0 20px;
    font: 15px 'Roboto', sans-serif;
    color: #fff;
    
    span {
      font-weight: 100;
      margin-left: 5px;
      
      &.current-slide-number {
        font-weight: 900;
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }
  
  .button-icon-holder {
    display: inline-block;
    vertical-align: middle;
  }
  
  .close {
    background: none;
  }
}
