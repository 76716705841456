.scroll-btn-block {
  width: 100%;
  display: block;
  text-align: center;
}

.scroll-down-btn {
  @extend .more-btn;
  display: inline-block;
  transition: $ease-normal;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  
  &:hover {
    border-color: #fff;
    
    .icon {
      transform: translate3d(0, 50px, 0);
    }
    
    .icon-hover {
      transform: translate3d(0, 0, 0);
    }  
  }  

  .icon,
  .icon-hover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: $ease-back;
  }
  
  .icon-hover {
    transform: translate3d(0, -50px, 0);
  }
}