.zorzo-clients {
  background: #000;
  text-align: center;
  overflow: hidden;
  
  h1 {
    font: 42px/72px 'Open Sans', sans-serif;
    color: #000;
    background: $highlight-color;
    font-weight: 100;
    padding: 0 10px;
    display: inline;
    
    @include max-screen($m-screen) {
      font-size: 25px;
      line-height: 42px;
    }
  }
  
  h2 {
    font: 26px 'Open Sans', sans-serif;
    color: #fff;
    text-transform: uppercase;
    display: block;
    font-weight: 800;
    
    @include max-screen($m-screen) {
      font-size: 18px;
      line-height: 25px;
    }
  }
  
  p {
    font: 14px/24px 'Roboto', sans-serif;
    color: #fff;
    display: block;
    margin-top: 30px;
    margin-bottom: 100px;
  }
  
  .slide {
    img {
      width: 80%;
      max-width: 570px;
    }
  }
  
  .control-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 2px solid $highlight-color;
    
    @include max-screen($m-screen) {
      position: relative;
      margin-top: 50px;
      margin-bottom: 70px;
    }
    
    &.next {
      right: 0;
      
      @include max-screen($m-screen) {
        margin-left: 50%;
      }
    }
    
    &.prev {
      left: 0;
    }
  }
  
  .slide-btn {
    margin: 100px 0;
    
    @include max-screen($m-screen) {
      display: none;
    }
  }
}