.home-contact {
  padding-bottom: 100px;
  background: url('../images/bg6.jpg') center center no-repeat fixed;
  background-size: cover;
  padding-top: 60px;
  
  @include max-screen($m-screen) {
    background: #fff;
  }
  
  h1 {
    font: 26px/27px 'Open Sans', sans-serif;
    color: #fff;
    display: block;
    text-transform: uppercase;
    margin-top: 80px;
    font-weight: 800;
    padding-left: 10px;
    
    @include max-screen($m-screen) {
      color: #000;
    }
  }
  
  .home-contact__block {
    width: 49%;
    display: inline-block;
    vertical-align: top;
    
    &:nth-child(1) {
      @include max-screen($m-screen) {
        max-width: 100%;
        width: 100%;
        display: block;
      }
    }
    
    &:nth-child(2) {
      margin-top: 118px;
      
      @include max-screen($m-screen) {
        display: none;
      }
        
      .facebook-widget {
        display: inline-block;
      }
      
      .icons {
        float: right;
      }
      
      .icon {
        display: inline-block;
        margin-left: 10px;
        transition: $ease-normal;
        
        &:hover {
          opacity: .7;
        }
      }
    }
  }
  
  .home-contact__contact-box {
    max-width: 270px;
    background: $highlight-color;
    padding: 30px;
    
    @include max-screen($m-screen) {
      width: 100%;
      max-width: 100%;
    }
    
    ul {
      padding: 0;
      margin: 0;
      display: block;
    }
    
    li {
      font: 14px/24px 'Roboto', sans-serif;
      color: #000;
      font-weight: 300;
      display: block;
    }
    
    .gmaps {
      margin: 20px 0;
      display: block;
    }
    
    .contact-btn {
      font: 18px/24px 'Roboto', sans-serif;
      color: #000;
      display: block;
      font-weight: 700;
    }
  }
  
  .home-contact__form {
    width: 100%;
    margin-top: 20px;
    
    .slide-btn {
      width: 100%;
      cursor: pointer;
    }
    
    input,
    select,
    textarea {
      width: 100%;
      background: rgba(0, 0, 0, .7);
      font: 14px 'Roboto', sans-serif;
      font-weight: bolder;
      padding: 8px;
      color: #fff;
      border: 0;
      transition: $ease-normal;
      transition-duration: 100ms;
      margin-bottom: 4px;
      display: block;
      resize: none;
            
      &:active,
      &:focus {
        outline: 1px solid $highlight-color;
      }
    }
  }
}