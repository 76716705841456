.home-contact--section {
  background-image: url('../images/bg-contato-head.jpg');
  padding-top: 150px;
}

.home-contact__block.home-contact__block--contact {
  
  h1 {
    @include max-screen($m-screen) {
      color: #fff;
    }
  }
  
  .home-head__phrase {
    @include max-screen($l-screen) {
      width: auto;
    }
    
    @include max-screen($m-screen) {
      display: block;
    }
    
    h1 {
      @include max-screen($l-screen) {
        font-size: 40px;
        
        &:last-child {
          float: right;
        }
      } 
    }
  }
}

.home-contact--bg-white {
  background: #fff;
  color: #000;
}

.home-contact__block--to-block-on-mobile {
  @include max-screen($l-screen) {
    width: 100% !important;
    display: block !important;
  }
  
  @include max-screen($m-screen) {
    width: 100% !important;
    display: block !important;
  }
  
  .home-contact__form {
    @include max-screen($m-screen) {
      width: 100%;
    }
  }
  
  .heading--black {
    color: #000;
  }
}


.map-contact {
  width: 100%;
  height: 700px;
  background: #ddd;
  position: relative;
  
  .map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.calculate-route {
  width: 100%;
  max-width: 470px;
  min-width: 320px;
  background: rgba(178, 180, 178, .7);
  position: absolute;
  top: 20px;
  right: 20%;
  z-index: 99999999;
  padding: 15px 25px;
  
  @include max-screen($m-screen) {
    left: 0;
    right: 0;
    margin: auto;
  }
  
  h1 {
    font: 18px 'Open Sans', sans-serif;
    font-weight: 900;
    color: #000;
    display: block;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
  
  .send-location {
    font: 14px 'Roboto', sans-serif;
    color: #000;
    text-decoration: underline;
    font-weight: 900;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    transition: $ease-normal;
    
    &:hover {
      color: $highlight-color;
      
      .pin {
        background: url('../images/mini-pin-hover.png') center center no-repeat;
      }
    }
    
    .pin {
      width: 17px;
      height: 23px;
      background: url('../images/mini-pin.png') center center no-repeat;
      background-size: cover;
      transition: $ease-normal;
      display: inline-block;
      vertical-align: bottom;
      margin-right: 5px;
    }
  }
  
  input {
    background: rgba(0, 0, 0, .7);
    border: 0;
    padding: 5px;
    font: 14px/30px 'Roboto', sans-serif;
    color: #fff;
    font-weight: 900;
    
    @include max-screen($s-screen) {
      width: 100%;
      display: block;
      margin-bottom: 5px;
    }
    
    &:focus,
    &:active {
      outline: none;
    }
  }
  
  .slide-btn {
    width: 100%;
    cursor: pointer;
    margin-top: 5px;
  }
}