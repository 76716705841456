.another-cases {
  width: 100%;
  display: block;
  font-size: 0;
  padding: 0;
  
  .another-case {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    
    @include max-screen($m-screen) {
      width: 100%;
      height: 200px;
      
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
    
    .more-btn {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      transform: translate3D(0, 0, 0);
    }
    
    img{
      width: 100%;
      display: block;
      transition: $ease-normal;
      transition-duration: 400ms;
    }
  }
}