.history-block {
  padding-bottom: 100px;
  background: url('../images/bg4.jpg') center center no-repeat fixed;
  background-size: cover;
  text-align: center;
  
  @include max-screen($m-screen) {
    background: url(../images/bg4_mobile.jpg) center center no-repeat fixed;
    background-size: cover;
  }
  
  h1 {
    font: 42px/62px 'Open Sans', sans-serif;
    color: #000;
    background: $highlight-color;
    padding: 0 10px;
    display: inline;
    margin: 0;
    margin-bottom: 30px;
    
    @include max-screen($m-screen) {
      width: 80%;
      display: inline;
      margin: 0 auto;
      font-size: 25px;
      line-height: 39px;
    }
  }
  
  h2 {
    font: 26px 'Open Sans', sans-serif;
    font-weight: 800;
    color: #fff;
    display: block;
    margin: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    
    
    @include max-screen($m-screen) {
      width: 80%;
      display: block;
      margin: 30px auto;
      font-size: 18px;
      line-height: 21px;
    }
  }
  
  p {
    font: 14px 'Roboto', sans-serif;
    font-weight: 300;
    color: #fff;
    display: block;
    margin: 0;
    margin-bottom: 30px;
  }
}