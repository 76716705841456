.about-section {
  background: url('../images/bg3.jpg') center center no-repeat fixed;
  background-size: cover;
  color: #fff;
  font-size: 0;
  padding-bottom: 100px;
  @include cf();
  
  .short-line {
    margin-bottom: 60px;
    margin-top: 10px;
    
    @include max-screen($m-screen) {
      display: none;
    }
  }
}

.about-section__block {
  width: 50%;
  display: inline-block;
  max-width: 380px;
  vertical-align: top;
  
  .social-block {
    margin-top: 110px;
    font-size: 12px;
    
    @include max-screen($m-screen) {
      display: none;
    }
    
    div, a {
      display: inline-block;
      vertical-align: top;
    }
  }

  &:nth-child(2) {
    @include max-screen($m-screen) {
      display: none;
    }  
  }
  
  &:nth-child(3) {
    max-width: 446px;
    border: 2px solid $highlight-color;
    float: right;
    padding: 30px;
    
    @include max-screen($m-screen) {
      width: 100%;
      float: none;
      display: block;
      max-width: 100%;
    }
  }
  
  h1 {
    font: 26px/26px 'Open Sans', sans-serif;
    font-weight: 900;
    margin: 0;
    margin-top: 30px;
    text-transform: uppercase;
  }
  
  h2 {
    font: 26px/27px 'Open Sans', sans-serif;
    font-weight: 400;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
  }
  
  p {
    font: 14px/27px 'Roboto', sans-serif;
    font-weight: 100;
  }
}