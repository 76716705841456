.header {
  width: 100%;
  max-width: 100%;
  background: #000;
  color: #fff;  
  padding: 35px 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  transition: $ease-inout;
  z-index: 9;
  
  @include max-screen($l-screen) {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 0;
    max-height: 62px;
    overflow: hidden;
    
    &.open {
      max-height: 2000px;
      
      .toggle-menu--mobile {
        .line.top {
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          transform: rotate(45deg);
        }
        
        .line.middle {
          transform: scaleX(0);
        }
        
        .line.bottom {
          top: 0;
          bottom: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          transform: rotate(-45deg);
        }
      }
    }
  }
  
  .header__inner {
    width: 80%;
    margin: 0 auto;
    max-width: 960px;
    min-width: 960px;
    
    @include max-screen($l-screen) {
      min-width: 0;
      width: 100%;
    }
  }  
  
  .header__brand {
    display: inline-block;
    vertical-align: middle;
    transition: $ease-inout;
    overflow: hidden;
    max-width: 400px;
          
    img {
      @include max-screen($l-screen) {
        width: 130px;
      }
    }
    
    .header__brand-title {
      font-size: 0;
    }
  }
  
  .header__block {
    &--brand {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      z-index: 2;
      
      @include max-screen($l-screen) {
        width: 100%;
        background: #000;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
    
    &--nav {
      display: inline-block;
      vertical-align: middle;
      
      @include max-screen($l-screen) {
        background: #3f2a56;
        width: 100%;
        display: block;
        transition: $ease-normal;
        padding-bottom: 20px;
      }
    }
  }
  
  .header__collapse-button {
    border: 0;
    outline: none;
    background: transparent;
    cursor: pointer;
    margin-top: 2px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 35px;
    transition: $ease-inout;
    
    @include max-screen($l-screen) {
      display: none;
    }
    
    &:focus {
      outline: none;
    }
  }
  
  .header__nav {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
  }
  
  .header__menu-item {
    font: 12px 'Roboto', sans-serif;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    line-height: 24px;
    margin: 0 15px;
    
    &:first-child {
      margin-left: 0;
    }
    
    &:last-child {
      margin-right: 0;
    }
    
    @include max-screen($l-screen) {
      &:nth-child(3),
      &:nth-child(4), {
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
      }
      
      font-size: 14px;
      display: block;
      text-align: center;
      margin: 0;
      padding: 10px 0;
      text-transform: uppercase;
      font-weight: 800;
      margin: 10px 0;
    }
    
    a {
      position: relative;
      display: block;
      transition: $ease-back;
      
      &:after {
        content: '';
        width: 100%;
        height: 3px;
        background: $highlight-color;
        display: block;
        position: absolute;
        top: 120%;
        left: 0;
        transition: $ease-back;
        transform: scaleX(0);
      }
      
      &:hover {
        color: #898d8d;
      }
      
      &:hover:after,
      &.active:after {
        transform: scaleX(1);
      }
    }
  }
  
  .header__social {
    display: inline-block;
    margin-left: 40px;
    
    @include max-screen($l-screen) {
      display: block;
      margin-left: 0;
      margin-top: 20px;
    }
  }
  
  .header__social-item {
    display: inline-block;
    vertical-align: middle;
    transition: $ease-normal;
    margin: 0 5px;
    border: 0;
    width: 19px;
    height: 30px;
    border-radius: 0;
    
    img {
      display: block;
      
      @include max-screen($l-screen) {
        width: 20px;
      }
    }
    
    a {
      display: block;
      border: 0 none;
    }
    
    &:hover {
      opacity: 1;
    }
  }
  
  .header__lang {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
    
    @include max-screen($l-screen) {
      display: block;
      margin-left: 0;
      width: 100%;
    }
  }
  
  .header__lang-item {
    font: 12px 'Roboto Condensed', sans-serif;
    color: #898d8d;
    display: none;
    
    @include max-screen($l-screen) {
      display: inline-block;
      padding: 10px 0;
      width: 32%;
    }
    
    &.current {
      display: block;
      @include max-screen($l-screen) {
        display: inline-block;
      }
    }
  }
  
  .header__lang-choose {
    position: absolute;
    top: 120%;
    left: 50%;
    margin-left: -15.3px;
    background: none;
    border: 0;
    
    @include max-screen($l-screen) {
      display: none;
    }
    
    img {
      display: block;
    }
  }
  
  .toggle-menu--mobile {
    width: 30px;
    height: 19px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    opacity: 0;
    visibility: hidden;
    background: none;
    border: 0;
    cursor: pointer;
    
    @include max-screen($l-screen) {
      opacity: 1;
      visibility: visible;
    }
    
    &:hover {
      .line {
        width: 70%;
      }
    }
    
    &:active,
    &:focus {
      outline: none;
      border: 0
    }
    
    .line {
      width: 100%;
      height: 3px;
      background: $highlight-color;
      position: absolute;
      transition: all 160ms ease;
      margin: auto;
      display: block;
      
      &.top {
        top: 0;
        right: 0;
        left: 0;
      }
      
      &.middle {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transition-delay: 100ms;
      }
      
      &.bottom {
        bottom: 0;
        right: 0;
        left: 0;
        transition-delay: 200ms;
      }
    }
  }
}

// Menu collapse
.header.collapse {
  max-width: 80px;
  left: 6%;
  
  @include max-screen($l-screen) {
    max-width: 70px;
    max-height: 100px;
  }
  
  .toggle-menu--mobile {
    @include max-screen($l-screen) {
      right: 0;
      left: 0;
      top: -20px;
    }
  }
  
  .header__brand {
    transition-delay: 200ms;
    max-width: 40px;
    margin-left: 10px;
    
    @include max-screen($l-screen) {
      max-width: 30px;
      padding-bottom: 100px;
    }
  }
  
  .header__collapse-button {
    transform: rotate(180deg);
    margin-right: 20px;
    margin-left: 0px;
  }
}