.footer {
  width: 100%;
  background: #000;
  position: relative;
  height: 300px;
  padding: 60px 0;
  @include cf();

  @include max-screen($m-screen) {
      height: auto;
  }
  
  .container {
    @include max-screen($m-screen) {
      width: 70%;
    }
    @include max-screen($s-screen) {
      width: 80%;
      text-align: center;
    }
  }
  
  .mobile-logo {
    display: none;
    margin: 0 auto;

    @include max-screen($m-screen) {
      display: block;
      margin-bottom: 30px;
    }
  }
  
  .footer__logo {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5%;
    float: left;
    
    @include max-screen($m-screen) {
      display: none;
    }
  }
  
  .footer__block {
    display: inline-block;
    vertical-align: middle;

    .footer__associations {
        float: left;
        display: inline-block;
        margin-right: 30px;

        @include max-screen($m-screen) {
          margin: 0 auto;
          width: 100%;
          text-align: center;
        }

        h2 {
            margin-left: 10px;
            margin-bottom: 20px;

            @include max-screen($s-screen) {
              margin-left: 0;
            }
        }
    }
    .footer__awards {
        float: left;
        display: inline-block;

        @include max-screen($m-screen) {
          margin: 0 auto;
          width: 100%;
          text-align: center;
        }

        h2 {
            margin-bottom: 10px;

            @include max-screen($m-screen) {
              margin-top: 30px;
            }
        }
    }
  }
  
  .footer__logo-asaw {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  
  h2 {
    font: 12px 'Roboto', sans-serif;
    color: #707372;
    display: block;
  }
  
  .copyright {
    display: inline-block;
    float: right;
    vertical-align: bottom;
    margin-top: 20px;
    text-align: right;
    
    @include max-screen($m-screen) {
      float: none;
      text-align: left;
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;
      width: 100%;
    }
  }
  
  p {
    font: 12px/24px 'Roboto', sans-serif;
    color: #707372;
  }
  
  .brava {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    right: 0;
    left: 0;
    bottom: 30px;
    margin: auto;
    transition: $ease-back;
    
    &:hover {
      transform: scale(1.2);
    }
    
    img {
      width: 100%;
      display: block; 
    }
  }
}