.thumbnails-gallery {
  border-top: 0px solid $highlight-color;
  background: #000;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: $ease-inout;
  
  &.open {
    border-top: 5px solid $highlight-color;
    max-height: 100px;
  }
  
  .list-of-thumbnails {
    display: flex;
    width: 100%;
    transition: $ease-inout;
  }

  .thumb-item {
    margin: 0;
    transition: $ease-normal;
    opacity: .5;
    min-width: 10%;
    
    @include max-screen($l-screen) {
      min-width: 12.5%;
    }
    
    @include max-screen($m-screen) {
      min-width: 20%;
    }
    
    @include max-screen($s-screen) {
      min-width: 25%;
    }
    
    @include max-screen($xs-screen) {
      min-width: 50%;
    }
    
    &.current, 
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    
    img {
      width: 100%;
      display: block;
      margin: 0;
    }
  }
}