.about-section--zorzo {
  @extend .about-section;
  background-image: url('../images/bg_filosofia.jpg');
  
  @include max-screen($m-screen) {
    background: #3f2a55;
  }
  
  .scroll-btn-block {
    margin-top: 50px;
  }
  
  @include max-screen($m-screen) {
    .about-section__block:nth-child(2) {
      display: block;
      width: 100%;
      max-width: 100%;
      margin-bottom: 100px;
    }
    
    .short-line {
      display: block;
    }
  }
}