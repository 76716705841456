.labeled-icon-btn {
  display: inline-block;
  background: none;
  border: 0;
  
  &:hover,
  &.active {
    cursor: pointer;
        
    .icon {
      background: $highlight-color;
      
      span,
      div {
        background: #000;
        color: #000;
      }
      
      .text {
        background: none;
      }
      
      img {
        &.image {
          opacity: 0;
          visibility: hidden;
        }
        
        &.hover {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  
  .label {
    font: 16px 'Roboto', sans-serif;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    font-weight: 900;
    margin-right: 10px;
  }
  
  .icon {
    width: 54px;
    height: 54px;
    display: inline-block;
    vertical-align: middle;
    border: 2px solid $highlight-color;
    transition: all 400ms ease;
    position: relative;
    border-radius: 50%;
    
    span,
    div {
      font: 22px/48px 'Open Sans', sans-serif;
      color: #fff;
      display: block;
      font-weight: 600;
    }
    
    img {
      width: 45%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 00px;
      right: 0;
      margin: auto;
      transition: all 500ms $ease-normal;
      
      &.image {
        opacity: 1;
        visibility: visible;
      }
      
      &.hover {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}