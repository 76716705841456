
.cases-list{
  width: 100%;
  display: block;
}

.cases-list__item {
  width: 100%;
  overflow: hidden;
  display: block;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  position: relative;
  padding: 120px 0;
  
  .container {
    background: rgba(0, 0, 0, .5);
    padding: 60px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @include max-screen($m-screen) {
      width: 95%;
      margin: 0;
    }
  }
  
  .more-btn {
    display: inline-block;
    transform: translate3d(0, 0, 0);
    
    @include max-screen($m-screen) {
      display: none;
    }
  }
  
  .cases-list__item-block {
    width: 33%;
    display: inline-block;
    
    @include max-screen($m-screen) {
      display: none;    
    }
  
    &--right {
      display: flex;
      align-items: center;
      
      @include max-screen($m-screen) {
        display: inline-block;  
        margin-left: 50%;
        width: 50%;
        text-align: right;
      }
      
      img {
        display: inline-block;
        margin-right: 30px;
        
        @include max-screen($m-screen) {
          width: 100%;
          max-width: 200px;
        }
      }
    }
    
    h1 {
      font: 24px 'Open Sans', sans-serif;
      color: #fff;
      display: block;
      font-weight: 900;
      margin: 0;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    
    p {
      font: 16px/19px 'Open Sns', sans-serif;
      color: #fff;
      font-style: italic;
      display: block;
      margin: 0;
      padding: 0;
    }
  }
  
  .cases-list__item-product {
    display: block;
    position: absolute; 
    bottom: 0%;
    right: 0;
    left: 0;
    top: 0;
    margin: auto;
    width: 28%;
    
    
    @include max-screen($m-screen) {
      width: 40%;
      left: 20px;
      margin: auto 0;
    }
  }
}