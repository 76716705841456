.zorzo-principles {
  padding: 0;
  
  .home-services__block {
    pointer-events: none;
  }
  
  .home-services__block-head {
    position: relative;
    
    @include max-screen($m-screen) {
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 15px 15px;
        border-color: transparent transparent $highlight-color transparent;
        position: absolute;
        bottom: 100%;
        right: 0;
        left: 0;
        margin: auto;
      }
    }
  }
  
  .home-services__block-main {
    @include max-screen($m-screen) {
      display: block;
      
      &:after {
        display: none;
      }
    }
  }
  
  .slide {
    padding-top: 230px;
    padding-bottom: 200px;
    
    @include max-screen($m-screen) {
      background-color: #3900b5;
      background-size: auto 45% !important;
      background-attachment: scroll !important;
      background-position: top center !important;
    }
  }
  
  .controls {
    width: 100%;
    position: absolute;
    bottom: 60px;
    left: 0;
    display: block;
    
    .next {
      float: right;
    }
  }
}