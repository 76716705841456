.portfolio-grid {
  width: 100%;
  display: block;
  font-size:0;
  
  .tile {
    display: inline-block;
    width: 25%;
    background: #454049;
    overflow: hidden;
    
    h1 {
      max-width: 0;
      background: $highlight-color;
      display: inline;
      font: 30px/40px 'Open Sans', sans-serif;
      color: #000;
      position: absolute;
      top: 20px;
      left: 0;
      z-index: 2;
      transition: $ease-inout;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: clip;
      white-space: nowrap;
    }
    
    &:hover {
      cursor: pointer;
      
      h1 {
        max-width: 500px;
        padding-left: 20px;
        padding-right: 10px;
      }
            
      img {
        transition: transform 120s linear;
        opacity: 1;
        transform: translate3D(0, 0, 0) scale(3);
      }
    }
    
    @include max-screen($m-screen) {
      width: 50%;
    }
    
    &.big {
      width: 50%;
      
      @include max-screen($m-screen) {
        width: 50%;
      }
    }
    
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: .5;
      transition: transform 2s linear;
    }
  }
}