.zorzo-contact {
  background-color: $highlight-color;
  color: #000;
  text-align: center;
  padding-bottom: 100px;
  
  h1 {
    font: 47px/72px 'Open Sans', sans-serif;
    color: #000;
    font-weight: 100;
    display: block;
    margin: 0;
    
    @include max-screen($m-screen) {
      font-size: 25px;
      line-height: 42px;
    }
  }
  
  h2 {
    font: 26px 'Open Sans', sans-serif;
    color: #000;
    text-transform: uppercase;
    display: block;
    font-weight: 800;
    margin: 30px 0;
    
    @include max-screen($m-screen) {
      font-size: 18px;
      line-height: 25px;
    }
  }
  
  .slide-btn {
    transition: $ease-inout;
    
    &:hover {
      border-color: #000;
    }
  }
  
  img {
    display: block;
    margin: 0 auto;
    margin-top: 65px;
  }
}