.related-projects {
  width: 100%;
  font-size: 0;
  background: darken(#786988, 20%);
  
  .related-project {
    width: 25%;
    display: inline-block;
    vertical-align: top;
    opacity: .4;
    overflow: hidden;
    transition: $ease-normal;
    position: relative;
    
    &:hover {
      opacity: 1;
      
      img {
        transition: transform 120s linear;
        transform: translate3D(0, 0, 0) scale(3);
      }
      
      .title {
        transform: translate3D(0, 0, 0);
      }
    }
    
    .more-btn {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      z-index: 4;
    }
    
    .title {
      position: absolute;
      bottom: 20px;
      left: 0;
      transition: $ease-inout;
      transform: translate3D(-100%, 0, 0);
    }
    
    h1 {
      display: inline;
      background: $highlight-color;
      font: 23px/35px 'Open Sans', sans-serif;
      color: #000;
      padding: 0 10px;
      padding-left: 15px;
      margin: 0;
    }
    
        
    img {
      display: block;
      width: 100%;
      margin: 0;
    }
  }
}