.home-services {
  background: url('../images/bg2.jpg') top center no-repeat scroll;
  padding-top: 0;
  padding-bottom: 50px;
  background-size: cover;
  
  .container {
    @include max-screen($m-screen) {
      width: 100%;
    }
  }
  
  h1 {
    margin: 0;
  }
  
  .cases-button-block {
    text-align: center;
    margin-top: 50px;
    
    @include max-screen($m-screen) {
      display: none;
    }
  }
}

.home-services__blocks-holder {
  width: 100%;
  display: block;
  font-size: 0;
  text-align: center;
}

.home-services__block {
  width: 32.6%;
  display: inline-block;
  font-size: 12px;
  position: relative;
  vertical-align: top;
  text-align: left;
  
  @include max-screen($m-screen) {
    width: 100%;
  }
  
  &:nth-child(2) {
    margin: 0 1%;
    
    @include max-screen($m-screen) {
      margin: 0;
    }
  }

  .home-services__block-head {
    width: 100%;
    background: $highlight-color; 
    display: block;
    padding: 20px 30px;
    padding-top: 50px;
    position: absolute;
    bottom: 100%;
    left: 0;
    
    @include max-screen($m-screen) {
      position: relative;
      padding-left: 64px;
      border-bottom: 1px solid #cbc531;
      padding-top: 40px;
      padding-bottom: 30px;
      
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 30px;
        bottom: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 11px 0 11px 10px;
        border-color: transparent transparent transparent #cbc531;
      }
    }
    
    .title {
      font: 26px/26px 'Open Sans', sans-serif;
      color: #000;
      text-transform: uppercase;
      font-weight: 900;
      position: relative;
      margin: 0;
      
      &:after {
        content: '';
        width: 73px;
        height: 3px;
        position: absolute;
        top: -15px;
        left: 0;
        background: #000;
      }
    } 
    
    .number {
      width: 54px;
      height: 54px;
      display: block;
      border: 3px solid #fff;
      position: absolute;
      font: 26px/54px 'Roboto Condensed', sans-serif;
      top: 10px;
      right: 10px;
      text-align: center;
      border-radius: 50%;
      font-weight: bold;
      line-height: 50px;
      
      @include max-screen($m-screen) {
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  
  .home-services__block-main {
    background: #cbc531;
    width: 100%;
    padding: 10px 20px;
    min-height: 120px;
    position: relative;
    
    @include max-screen($m-screen) {
      display: none;
    }
    
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #cbc531 transparent transparent transparent;
    }
    
    p {
      font: 18px/20px 'Roboto Condensed', sans-serif;
      color: #000;
      font-weight: 600;
      font-style: italic;
    }
  }
}

.home-services__hero-text {
  width: 80%;
  margin: 60px auto;
  display: block;

  @include max-screen($s-screen) {
    margin-bottom: 0;
  }

  h1 {
    font: 25px/31px 'Open Sans', sans-serif;
    color: #fff;
    font-weight: 900;
    display: block;
    margin: 0;
    text-align: center;
    
    @include max-screen($m-screen) {
      font-size: 18px;
      line-height: 21px;
    }
  }
  
  p {
    font: 14px/26px 'Roboto', sans-serif;
    color: #fff;
    text-align: center;
    display: block;  
  }
}

.home-services__infos-blocks,
.services-modal {
  border-top: 3px solid $highlight-color;
  text-align: center;
  
  @include max-screen($l-screen) {
    display: none; 
  }
  
  .home-services__infos-block {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: left;
    
    &:nth-child(2) {
      margin: 0 3%;
    }
    
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 15px 15px;
      border-color: transparent transparent $highlight-color transparent;
      position: absolute;
      bottom: 100%;
      right: 0;
      left: 0;
      margin: auto;
    }
  }
  
  .infos-block__list {
    padding: 30px;
    height: 300px;
    background: rgba(255, 255, 255, .1);
    
    li {
      font: 13px 'Open Sans', sans-serif;
      color: #fff;
      font-weight: bold;
      margin-bottom: 15px;
      display: block;
    }
  }
  
  .home-services__services-labels {
    background: #20142c;
    position: relative;
    
    .more-btn {
      position: absolute;
      top: -27.5px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 3;
    }
    
    li {
      font: 13px 'Open Sans', sans-serif;
      color: #fff;
      display: block;
      margin-bottom: 5px;
      background: url('../images/plus_symbol.png') left center no-repeat;
      padding-left: 15px;
    }
    
    &.show-text-box {
      .services-labels__text {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  
  .services-labels__content {
    min-height: 377px;
    padding: 30px;
    padding-top: 60px;
    overflow: hidden;
    position: relative;
  }
  
  .services-labels__text {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 30px;
    background-color: #3f2a56;
    border: 2px solid $highlight-color;
    transform: translate3d(0, -100%, 0);
    transition: $ease-inout;
    
    p {
      font: 14px/22px 'Roboto', sans-serif;
      color: #fff;
      font-style: italic;
    }
  }
}