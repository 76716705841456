.home-head {
  background: url('../images/banner1.jpg') top center no-repeat fixed;
  min-height: 800px;
  text-align: right;
  background-size: cover;
  
  @include max-screen($m-screen) {
    min-height: $m-screen;
  }
  
  @include max-screen($s-screen) {
    min-height: 400px;
    background-size: auto 400px;
    background-position-y: 50px;
  }
}

.home-contact--section .home-head__phrase,
.home-head__phrase {
  max-width: 384px;
  display: inline-block;
  margin-top: 70px;
  
  @include max-screen($l-screen) {
    width: 50%;
  }
  
  @include max-screen($s-screen) {
    width: 60%;
  }
  
  h1 {
    margin: 0;
    font: 74px/74px 'Open Sans';
    font-weight: 300;
    color: #b2aaa3;
    display: inline-block;
    background: rgba(0, 0, 0, .8);  
    text-transform: uppercase;
    padding: 0 10px;
    margin-bottom: 5px;
    
    @include max-screen($m-screen) {
      font-size: 50px;
      line-height: 60px;
    }
    
    @include max-screen($s-screen) {
      font-size: 35px;
      line-height: 45px;
    }
    
    &:nth-child(1) {
      float: left;
      padding-left: 20px;
    }
    
    &:nth-child(2) {
      width: 100%;
      text-align: center;
    }
  }
}

.home-head__buttons {
  margin-top: 80px;
  
  @include max-screen($m-screen) {
    display: none;
  }
  
  .home-head__button {
    &--slide {
      @extend .slide-btn;
    }
    
    &--grey {
      padding: 17px 0; 
      @extend .slide-btn;
      border: 0;
      background: linear-gradient(90deg, #c9c8cc, #473e4e);
    }
  }
}
