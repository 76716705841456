.portfolio-search {
  background-color: #000;
  padding-top: 150px;
  text-align: center;
  padding-bottom: 30px;
  
  .container {
    position: relative;
  }

  .search-holder {
    width: 100%;
    border: 2px solid $highlight-color;
    display: flex;
    padding: 30px 30px;
    position: relative;
    
    &:after {
      content: '';
      width: 73px;
      height: 3px;
      background: $highlight-color;
      position: absolute;
      top: 30px;
      left: 30px;
    }
  }
  
  .search-input {
    width: 100%;
    background: none;
    border: 0;
    font: 26px 'Open Sans', sans-serif;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
    text-align: left;
    
    &:focus,
    &:active {
      border: 0;
      outline: none;
    }
  }
  
  .categories {
    background: none;
    border: 0;
    font: 16px 'Roboto', sans-serif;
    color: #fff;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $ease-inout;
    
    &:hover {
      cursor: pointer;
      
      .icon {
        color: #000;
        background: $highlight-color;
      }
    }
    
    .icon {
      width: 54px;
      height: 54px;
      line-height: 54px;
      font-size: 22px;
      border: 2px solid $highlight-color;
      border-radius: 50%;
      font-style: none;
      margin-left: 10px;
      transition: $ease-inout;
    }
  }
}

.categories-suggestions {
  width: 100%;
  background: rgba(0, 0, 0, .8);
  z-index: 4;
  position: absolute;
  top: 100%;
  text-align: left;
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 600ms ease;
  transform: translate3D(0, 20px, 0);
  
  &.open {
    transform: translate3D(0, 0px, 0);
    opacity: 1;
    visibility: visible;
  }
  
  .actions {
    display: flex;
    margin-top: 10px;
    align-items: center;
    
    .see-all {
      font: 20px 'Open Sans', sans-serif;
      color: #fff;
      font-weight: 600;
      border: 2px solid $highlight-color;
      display: inline;
      padding: 0 5px;
    }
    
    .close {
      margin-left: auto;
      background: none;
      border: 0;
      width: 20px;
      height: 20px;
      
      &:hover {
        .line-vertical,
        .line-horizontal {
          background: #fff;
          transform: rotate(135deg) translate3D(0, 0, 0);
        }
      }      
      
      .line-vertical,
      .line-horizontal {
        background: $highlight-color;
      }
    }
  }
  
  .list-of-suggestions {
    display: block;
    width: 100%;
    margin-top: 30px;
  }
  
  .suggestion-item {
    background: $highlight-color;
    font: 18px 'Open Sans', sans-serif;
    padding: 5px;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 600;
    transition: all 900ms $ease-normal;
      
    &:hover {
      background: #fff;
      cursor: pointer;
    }
  }
}