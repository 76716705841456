html,
body {
  margin: 0;
  padding: 0;
  display: block;
  font-family: $font-family;
  background-color: $ui-color;
  color: $font-color;
  min-height: 100%;
  overflow-x: hidden;
}

body {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea:focus,
input:focus,
select:focus {
  transition: all 0.5s ease;
}

textarea:focus,
input:focus,
select:focus {
  outline: 1px rgba(255,255,0,0.4) solid;
}

.clear {
  clear:both;
}

.wow {
  visibility: hidden;
}

.section {
  display: block;
  width: 100%;
  position: relative;
  padding-top: 122px;
  
  @include max-screen($l-screen) {
    padding-top: 72px;
  }
}

button:active,
button:focus {
  outline: none;
}

.short-line {
  width: 0;
  height: 3px;
  display: block;
  background: $highlight-color;
  transition: $ease-inout;
  
  &.animated {
    width: 70px;
  }
}

strong {
  font-weight: 100;
  background: $highlight-color;
  color: #000;
}

.full-line {
  width: 100%;
  height: 3px;
  display: block;
  background: $highlight-color;
  
  &.section-sep {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.animated-button {
  @extend .slide-btn;
}

.row {
  width: 100%;
  display: block;
}

.container {
  width: 90%;
  max-width: 960px;
  margin: 0 auto;

  @include max-screen($m-screen) {
    width: 95%;
  }
}

.highlight {
  color: $highlight-color;
}
