.services-modal {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  overflow: scroll;
  padding-top: 150px;
  padding-bottom: 50px;
  background: linear-gradient(180deg, #595b65, #3f2a56);
  top: 0;
  left: 0;
  opacity: 1;
  transition: $ease-inout;
  text-align: center;
  transform: translate3d(-100%, 0, 0);
  
  .container {
    width: 80%;
  }

  &.show {
    transform: translate3d(0, 0, 0);
  }
  
  .close {
    position: absolute;
    top: 65px;
    right: 10%;
  }
  
  .home-services__infos-block {
    width: 100%;
    margin: 0;
    opacity: 1;
    visibility: visible !important;
    display: block;
    border-top: 2px solid $highlight-color;
    
    .more-btn.to-close {
      display: none;
    }
  }
  
  p {
    font: 14px/24px 'Roboto', sans-serif;
    color: #fff;
    margin: 50px 0;
  }
  
  .home-services__block {
    width: 32.6%;
    display: inline-block;
    font-size: 12px;
    position: relative;
    vertical-align: top;
    text-align: left;
    
    @include max-screen($m-screen) {
      width: 100%;
    }
    
    &:nth-child(2) {
      margin: 0 1%;
      
      @include max-screen($m-screen) {
        margin: 0;
      }
    }
    
    .home-services__block-head {
      width: 100%;
      background: $highlight-color; 
      display: block;
      padding: 20px 30px;
      padding-top: 50px;
      position: absolute;
      bottom: 100%;
      left: 0;
      
      @include max-screen($m-screen) {
        position: relative;
        padding-left: 20px;
        border-bottom: 0;
        padding-top: 40px;
        padding-bottom: 30px;
        
        &:after {
          display: none;
        }
      }
      
      .title {
        font: 26px/26px 'Open Sans', sans-serif;
        color: #000;
        text-transform: uppercase;
        font-weight: 900;
        position: relative;
        margin: 0;
        
        &:after {
          content: '';
          width: 73px;
          height: 3px;
          position: absolute;
          top: -15px;
          left: 0;
          background: #000;
        }
      } 
      
      .number {
        width: 54px;
        height: 54px;
        display: block;
        border: 1px solid #fff;
        position: absolute;
        font: 26px/54px 'Roboto Condensed', sans-serif;
        top: 10px;
        right: 10px;
        text-align: center;
        border-radius: 50%;
        
        @include max-screen($m-screen) {
          bottom: auto;
          margin: auto;
        }
      }
    }
    
    .home-services__block-main {
      background: #cbc531;
      width: 100%;
      padding: 10px 20px;
      min-height: 120px;
      position: relative;
      
      @include max-screen($m-screen) {
        display: block;
      }
      
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #cbc531 transparent transparent transparent;
      }
      
      p {
        margin: 20px 0;
        font: 18px/20px 'Roboto Condensed', sans-serif;
        color: #000;
        font-weight: 600;
        font-style: italic;
      }
    }
  }
}