.more-btn {
  width: 54px;
  height: 54px;
  border: 2px solid $highlight-color;
  position: relative;
  background: none;
  border-radius: 50%;
  transition: $ease-normal;
  cursor: pointer;
  overflow: hidden;
  
  &:hover {
    border-color: transparent;
    
    .hover {
      transform: skewX(-30deg) translate3d(0, 0, 0);
    }
    
    .line-horizontal,
    .line-vertical {
      background-color: #000;
    }
  }
  
  &:active,
  &:focus {
    outline: none;
  }
  
  &.close {
    transition: $ease-inout;
    background: #000;
    
    .line-horizontal {
      width: 15px;
      transform: translate3D(0, 0, 0) rotate(225deg);  
    }
    
    .line-vertical {
      height: 15px;
      transition-duration: 400ms;
      transform: translate3D(0, 0, 0) rotate(225deg);  
    }
    
    .line-horizontal,
    .line-vertical {
      background-color: #fff;
    }
    
    &:hover {
      .line-horizontal,
      .line-vertical {
        background-color: #000;
      }
    }
  }
  
  .hover {
    width: 200%;
    height: 100%;
    background: $highlight-color;
    position: absolute;
    top: 0;
    left: -20px;
    transform: skewX(-30deg) translate3d(-100px, 0, 0);
    transition: $ease-normal;
  }
  
  .line-horizontal {
    width: 15px;
    height: 2px;
    transition: $ease-normal;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    transform-origin: 50% 50%;
    z-index: 1;
  }
  
  .line-vertical {
    width: 2px;
    height: 15px;
    transition: $ease-normal;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    transform-origin: 50% 50%;
    z-index: 1;
  }
}