.full-width-parallax-slider {
  width: 100%;
  display: block;
  position: relative;
  
  .slide {
    width: 100%;
    position: absolute;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    transition: $ease-inout;
    transition-property: transform, opacity;
    top: 0;
    left: 0;
    
    .container {
      transition: $ease-inout;
      transition-duration: 800ms;
    }
    
    &.current {
      position: relative;
      transform: translate3D(0, 0, 0);  
      opacity: 1;
      
      .container {
        transform: translate3D(0, 0, 0);
      }
    }
    
    &.prev {
      transform: translate3D(-100%, 0, 0);
      opacity: 0;
      
      .container {
        transform: translate3D(-100%, 0, 0);
      }
    }
    
    &.next {
      transform: translate3D(100%, 0, 0);
      opacity: 0;
      
      .container {
        transform: translate3D(100%, 0, 0);
      }
    }
  }
}