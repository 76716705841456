.control-btn {
  @extend .scroll-down-btn;
  background-color: #000;
  border-color: #000;
  
  &.next {
    .icon {
      left: 3px;
    }
    
    .icon-hover {
      left: 3px;
      transform: translate3D(-40px, 0, 0);
    }
  }
  
  
  &.prev {
    .icon {
      transform: translate3D(0, 0, 0);
      left: -3px;
    }
    
    .icon-hover {
      left: -3px;
      transform: translate3D(40px, 0, 0);
    }
  }

  &:hover {
    background: $highlight-color;
    border-color: $highlight-color;
    
    &.next {
      .icon {
        transform: translate3D(40px, 0, 0);
      }
      
      .icon-hover {
        transform: translate3D(0, 0, 0);
      }
    }
    
    &.prev {
      .icon {
        transform: translate3D(-40px, 0, 0);
      }
      
      .icon-hover {
        transform: translate3D(0, 0, 0);
      }
    }
  }  
}
