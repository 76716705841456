.cases-head {
  background: url('../images/bg_cases.jpg') center center no-repeat;
  background-size: cover;
  padding: 0;
  position: relative;
  z-index: 2;
  
  .container {
    padding: 200px 0;
    position: relative;
    
    @include max-screen($m-screen) {
      padding: 90px 0;
    }
  }
  
  h1 {
    font: 62px 'Open Sans', sans-serif;
    color: #fff;
    margin-bottom: 40px;
    margin-top: 0;
    
    @include max-screen($m-screen) {
      font-size: 33px;
      line-height: 38px;
      margin-bottom: 20px;
    }
  }
  
  h2 {
    font: 42px/59px 'Open Sans',sans-serif;
    color: #000;
    text-transform: uppercase;
    background: $highlight-color;
    margin: 0;
    display: inline-block;
    margin-bottom: 10px;
    padding: 0 10px;
    
    @include max-screen($m-screen) {
      font-size: 26px;
      line-height: 26px;
    }
  }
}

.cases-showreel {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  padding: 20px 30px;
  transform: translate3d(0, 0, 0);
  
  @include max-screen($m-screen) {
    display: none;
  }
  
  h3 {
    font: 26px/54px 'Open Sans', sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    margin: 0;
    padding: 0;
    display: inline-block;
  }
  
  .cases-showreel__cta {
    float: right;
  }
  
  .cases-showreel__play-text {
    display: inline-block;
    vertical-align: middle;
    font: 16px 'Roboto', sans-serif;
    color: #fff;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .cases-showreel__play {
    @extend .more-btn;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    
    &:hover {
      .cases-showreel__play-icon--hover {
        transition-delay: 100ms;
        transform: translate3d(0, 0, 0);
      }
      
      .cases-showreel__play-icon {
        transform: translate3d(100px, 0, 0);
      }
    }
  }
  
  .cases-showreel__play-icon,
  .cases-showreel__play-icon--hover {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
    z-index: 3;
    transition: $ease-back;
  }
  
  .cases-showreel__play-icon--hover {
    transform: translate3d(-100px, 0, 0);
  }
}


.cases-head__buttons {
  margin-top: 80px;
  text-align: right;
  
  @include max-screen($m-screen) {
    display: none;
  }
  
  .cases-head__button {
    &--slide {
      @extend .slide-btn;
    }
    
    &--grey {
      padding: 17px 0; 
      @extend .slide-btn;
      border: 0;
      background: linear-gradient(90deg, #c9c8cc, #473e4e);
    }
  }
}