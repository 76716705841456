*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-input-placeholder {
  color: white;
}
:-moz-placeholder { /* Firefox 18- */
  color: white;
}

::-moz-placeholder {  /* Firefox 19+ */
  color: white;
}

:-ms-input-placeholder {  
  color: white;
}

::selection {
  background: #fcff00; /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: #fcff00; /* Gecko Browsers */
}