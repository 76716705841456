/*BLOG*/

.home-blog.blog {
  background: #000;
  padding: 150px 0 0;

  @include max-screen($m-screen){
    padding-top: 85px;
  }
  .control-btn {
    float: left;
    &.next {
      float: right;
    }
  }
  .controls {
    width: 100%;
    position: relative;
    top: 40%;
    left: 0;
    display: block;
  }
  .portfolio-grid {
    .tile {
      width: 100%;
      h1 {
        width: 100%;
        top: initial;
        bottom: 0;
        margin: 0;
        max-width: 500px;
        max-height: 0;
        background: #20142c;
        color: #fff;
        opacity: .8;
      }
      &:hover h1 {
        max-height: 40px;
      }
    }
    .owl-controls .owl-buttons {
      .owl-prev, .owl-next {
        width: 54px;
        height: 54px;
        position: relative;
        background: #000;
        border-radius: 50%;
        -webkit-transition: all 200ms ease;
        -moz-transition: all 200ms ease;
        -o-transition: all 200ms ease;
        transition: all 200ms ease;
        cursor: pointer;
        overflow: hidden;
        display: inline-block;
      }
      .owl-prev {
        float: left;
        margin-left: 20px;
        position: absolute;
        top: 50%;
        left: 25%;
        margin-top: -27px;
        @include max-screen($m-screen){
          left: 0;
        }
      }
      .owl-next {
        float: right;
        margin-right: 20px;
        position: absolute;
        top: 50%;
        right: 25%;
        margin-top: -27px;
        @include max-screen($m-screen){
          right: 0;
        }
      }
      .owl-prev:hover, .owl-next:hover {
        background: #fcff00;
      }
      .owl-prev::before {
        background: url(../images/arrow-left-icon.png);
        content: '';
        width: 11px;
        height: 19px;
        display: block;
        margin: 18px 19px;
      }
      .owl-next::before {
        background: url(../images/arrow-left-icon.png);
        content: '';
        width: 11px;
        height: 19px;
        display: block;
        margin: 18px 19px;
        background: url(../images/arrow-right-icon.png);
        margin: 18px 21px;
      }
      .owl-prev:hover::before {
        background: url(../images/arrow-left-icon-hover.png);
      }
      .owl-next:hover::before {
        background: url(../images/arrow-right-icon-hover.png);
      }
    }
  }
}

.posts {
  overflow: hidden;
  padding-top: 30px;
  .sidebar {
    float: right;
    width: 25%;
    max-width: 227px;

    @include max-screen($m-screen){
      width: 100%;
      float: left;
      margin: 20px auto;
      max-width: 100%;
    }
  }
  .posts-content {
    float: left;
    width: 75%;
    font-size: 14px;
    text-align: justify;
    line-height: 150%;

    @include max-screen($m-screen){
      width: 100%;
      text-align: left;
    }
    .thumb img {
      width: 100%;
      margin: 20px 0;
    }
    h2 {
      float: left;
      width: 83%;
      font: 28px 'Open Sans', sans-serif;
      font-weight: 800;
      text-transform: uppercase;
      @include max-screen($m-screen){
        width: 70%;
      }
      @include max-screen($xs-screen){
        font-size: 20px;
      }
      &::before {
        content: '';
        display: inline-block;
        border: 10px solid transparent;
        border-left-color: #fcff00;
      }
    }
    .date {
      width: 110px;
      height: 140px;
      background-color: #fcff00;
      font: 50px 'Open Sans', sans-serif;
      font-weight: 800;
      float: right;
      text-align: center;
      padding-top: 15px;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      @include max-screen($xs-screen){
        width: 30%;
      }
      span {
        display: block;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 20px;
      }
    }
    .more {
      margin: 50px auto 0;
      &::before {
        content: '';
        background: #e1e1e1;
        width: 100%;
        height: 3px;
        display: block;
        margin-bottom: -20px;
      }
      a {
        background-color: #e1e1e1;
        color: #000;
        padding: 10px 15px;
        margin: 0 auto;
        width: 150px;
        display: block;
        text-align: center;
        font-weight: bold;
        -moz-border-radius: 3px;
        border-radius: 3px;
        &:hover {
          background-color: #fcff00;
        }
        &::before {
          content: '';
          display: inline-block;
          border: 5px solid transparent;
          border-left-color: #fcff00;
          position: relative;
          left: -30px;
        }
        &:hover::before {
          border-left-color: #3f2957;
        }
      }
    }
  }
}

.home-insta.blog {
  background-color: #3f2957;
  padding-bottom: 0;
  h3 {
    font: 26px/54px 'Open Sans', sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    @include max-screen($m-screen){
      margin: 0;
    }
  }
  .container {
    position: relative;
  }
  .flashes {
    position: absolute;
    top: 30px;
    right: 0;
    float: right;
    @include max-screen($m-screen){
      position: initial;
      float: left;
      margin-bottom: 30px;
      width: 100%;
      max-width: 385px;
    }
  }
  .home-insta__tile {
    width: 12.5%;
    img {
      opacity: 1;
    }
    @include max-screen($m-screen) {
      width: 25%;
    }   
  }
}

.redes {
  background-color: #e1e1e1;

  @include max-screen($m-screen){
    img {
      width: 100%;
    }
  }

  &::before {
    content: '';
    display: table;
  }
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.social {
  text-align: center;
  margin: 20px 0;
  li {
    padding: 0 5px;
    display: inline-block;
  }
}

.mais-visitados {
  margin-top: 10px;
  overflow: hidden;
  .posts_visitados {
    position: relative;
    background-color: #e1e1e1;
    margin-bottom: 10px;
  }
  h3 {
    background-color: #3f2957;
    position: relative;
    margin: 0;
    padding: 25px 10px 50px;
    color: #fff;
    text-transform: uppercase;
    &::before {
      content: '';
      position: absolute;
      top: 70px;
      left: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #fcff00 transparent transparent transparent;
    }
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background: #fcff00;
      position: absolute;
      top: 70px;
      left: 0;
    }
  }
  .date {
    width: 69px;
    height: 89px;
    background-color: #fcff00;
    font: 35px 'Open Sans', sans-serif;
    font-weight: 800;
    float: right;
    text-align: center;
    padding-top: 5px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    span {
      display: block;
      font-size: 20px;
      text-transform: uppercase;
      line-height: 20px;
    }
  }
  img {
    width: 100%;
  }
  h2 {
    text-transform: uppercase;
    padding: 15px;
    margin: 0;
    font-size: 16px;
  }
}

.sidebar .categorias {
  background-color: #3f2957;
  color: #fff;
  padding-bottom: 20px;
  h3 {
    position: relative;
    margin: 0;
    padding: 25px 10px 50px;
    color: #fff;
    text-transform: uppercase;
    &::before {
      content: '';
      position: absolute;
      top: 70px;
      left: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #fcff00 transparent transparent transparent;
    }
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background: #fcff00;
      position: absolute;
      top: 70px;
      left: 0;
    }
  }
  ul {
    margin: 0 15px;
  }
  .suggestion-item {
    background: #fcff00;
    font: 15px 'Open Sans', sans-serif;
    color: #000;
    padding: 5px;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 600;
    -webkit-transition: all 900ms all 200ms ease;
    -moz-transition: all 900ms all 200ms ease;
    -o-transition: all 900ms all 200ms ease;
    transition: all 900ms all 200ms ease;
    &:hover {
      background: #fff;
      cursor: pointer;
    }
  }
}

.home-youtube {
  &.blog {
    background-color: #000;
    margin-top: 50px;
    padding-bottom: 0;
    @include max-screen($m-screen){
      margin-top: 0;
    }
    h3 {
      font: 26px/54px 'Open Sans', sans-serif;
      color: #fff;
      text-transform: uppercase;
      font-weight: 800;
      @include max-screen($m-screen){
        margin: 0;
      }
    }
  }
  article {
    padding: 50px 0;
    text-align: center;
  }
  &.blog {
    .container {
      position: relative;
    }
    .movimento {
      position: absolute;
      top: 30px;
      right: 0;
      float: right;
      @include max-screen($m-screen){
        position: initial;
        padding: 0 0 20px;
        margin: 0;
        width: 100%;
        max-width: 455px;
      }
    }
  }
}

.search-holder-blog {
  width: 100%;
  border: 2px solid #fcff00;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 30px;
  position: relative;
  margin-top: 50px;
  .search-input {
    width: 100%;
    background: none;
    border: 0;
    font: 26px 'Open Sans', sans-serif;
    color: #000;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
    text-align: left;
  }
  &::after {
    content: '';
    width: 73px;
    height: 3px;
    background: #fcff00;
    position: absolute;
    top: 20px;
    left: 30px;
  }
  button {
    border: 0 none;
    background: transparent;
  }
  .icon {
    width: 54px;
    height: 54px;
    line-height: 54px;
    font-size: 22px;
    border: 2px solid #fcff00;
    border-radius: 50%;
    font-style: none;
    margin-left: 10px;
    -webkit-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    display: block;
    cursor: pointer;
  }
  button:hover .icon {
    color: #000;
    background: #fcff00;
  }
  .icon img {
    width: 22px;
    height: 22px;
    margin: 15px 0;
  }
}

.navigation-holder {
  width: 100%;
  border: 2px solid #fcff00;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  position: relative;
  margin-bottom: 50px;

  @include max-screen($m-screen){
    text-align: center;
    display: block;
  }

  h3 {
    width: 100%;
    background: none;
    border: 0;
    font: 20px 'Open Sans', sans-serif;
    color: #000;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
    text-align: left;
    margin: 15px 10px;
  }
  &::after {
    content: '';
    width: 73px;
    height: 3px;
    background: #fcff00;
    position: absolute;
    top: 20px;
    left: 30px;
  }
  .lista, .lista-blog {
    background: none;
    border: 0;
    font: 16px 'Roboto', sans-serif;
    color: #000;
    font-weight: 900;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    margin-left: 15px;

    @include max-screen($m-screen){
      display: inline-block;
      span {
        float: left;
        line-height: 55px;
      }
    }
  }
  .icon {
    width: 54px;
    height: 54px;
    line-height: 54px;
    font-size: 22px;
    border: 2px solid #fcff00;
    border-radius: 50%;
    font-style: none;
    margin-left: 10px;
    -webkit-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    display: block;
    cursor: pointer;
  }
  .lista:hover .icon, .lista-blog:hover .icon {
    color: #000;
    background: #fcff00;
  }
}

.list-content .navigation-holder .lista .icon, .blog-content .navigation-holder .lista-blog .icon {
  color: #000;
  background: #fcff00;
}

.navigation-holder {
  .lista .icon span {
    background: url(../images/blog/buttons-navigation.png) -1px -1px;
    width: 23px;
    height: 23px;
    display: block;
    margin: 14px;
  }
  .lista-blog .icon span {
    background: url(../images/blog/buttons-navigation.png) -31px -1px;
    width: 25px;
    height: 23px;
    display: block;
    margin: 14px;
  }
}

/* LISTA BLOG */

.posts .posts-content {
  &.list-content {
    article {
      border-bottom: 3px solid #e1e1e1;
      overflow: hidden;
    }
    .thumb {
      width: 50%;
      float: left;
      @include max-screen($m-screen){
        width: 100%;
      }
    }
    .header-post, .more, .tags {
      float: right;
      width: 48%;
      @include max-screen($m-screen){
        width: 100%;
        text-align: left;
      }
    }
    .tags {
      @include max-screen($m-screen){
        margin: 10px 0;
      }
    }
    h2 {
      font-size: 23px;
      margin-bottom: 0;
      &::before {
        display: none;
      }
    }
    .date {
      width: auto;
      height: auto;
      background-color: transparent;
      padding: 0;
      float: left;
      clear: left;
      font-size: 14px;
      font-weight: 100;
      span {
        display: inline-block;
        font-size: 14px;
        text-transform: capitalize;
        line-height: 20px;
        margin: 0 3px;
      }
    }
    figure, .content {
      display: none;
    }
    .more {
      &::before {
        display: none;
      }
      margin: 5px 0;
      a {
        float: left;
        width: 200px;
        padding: 15px 0;
      }
    }
  }
  .tags .suggestion-item {
    background: #fcff00;
    font: 15px 'Open Sans', sans-serif;
    color: #000;
    padding: 5px;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 600;
    -webkit-transition: all 900ms all 200ms ease;
    -moz-transition: all 900ms all 200ms ease;
    -o-transition: all 900ms all 200ms ease;
    transition: all 900ms all 200ms ease;
    &:hover {
      background: #fff;
      cursor: pointer;
    }
  }
  > h3 {
    font: 26px 'Open Sans', sans-serif;
    color: #000;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    padding-top: 65px;
  }
  .scroll-down-btn {
    left: 50%;
    margin-left: -27px;
    &:hover {
      background-color: #3f2957;
    }
  }
  &.blog-content {
    .date small, .thumb {
      display: none;
    }
    figure {
      float: left;
      margin: 10px 0;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .content {
      clear: both;
      @include max-screen($m-screen){
        text-align: justify;
      }
    }
    .more {
      margin: 100px auto 0;
    }
    .tags {
      position: relative;
      bottom: 100px;
    }
  }
}

/*BLOG SINGLE*/

.home-blog.single .logos {
  margin-bottom: 0px;
  padding-bottom: 70px;
}

.posts.single {
  padding-top: 0;
  .posts-content.blog-content .tags {
    position: initial;
  }
}

.single {
  .share .social {
    margin-top: 45px;
    padding-top: 40px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #fcff00 transparent transparent transparent;
    }
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background: #fcff00;
      position: absolute;
      top: 0px;
      left: 0;
    }
  }
  .more-posts {
    h3 {
      float: left;
      font: 26px 'Open Sans', sans-serif;
      font-weight: 900;
      text-transform: uppercase;
      padding-left: 30px;
      position: relative;
      width: 100%;
      &::before {
        content: '';
        position: absolute;
        top: 60px;
        left: 10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #fcff00 transparent transparent transparent;
      }
      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background: #fcff00;
        position: absolute;
        top: 60px;
        left: 0;
      }
    }
    .owl-controls .owl-buttons {
      .owl-prev, .owl-next {
        width: 54px;
        height: 54px;
        position: relative;
        background: #000;
        border-radius: 50%;
        -webkit-transition: all 200ms ease;
        -moz-transition: all 200ms ease;
        -o-transition: all 200ms ease;
        transition: all 200ms ease;
        cursor: pointer;
        overflow: hidden;
        display: inline-block;
      }
      .owl-prev {
        float: left;
        margin-left: 20px;
        position: absolute;
        top: 55%;
        left: 0;
        @include max-screen($m-screen){
          left: 0;
        }
      }
      .owl-next {
        float: right;
        margin-right: 20px;
        position: absolute;
        top: 55%;
        right: 0;
        @include max-screen($m-screen){
          right: 0;
        }
      }
      .owl-prev:hover, .owl-next:hover {
        background: #fcff00;
      }
      .owl-prev::before {
        background: url(../images/arrow-left-icon.png);
        content: '';
        width: 11px;
        height: 19px;
        display: block;
        margin: 18px 19px;
      }
      .owl-next::before {
        background: url(../images/arrow-left-icon.png);
        content: '';
        width: 11px;
        height: 19px;
        display: block;
        margin: 18px 19px;
        background: url(../images/arrow-right-icon.png);
        margin: 18px 21px;
      }
      .owl-prev:hover::before {
        background: url(../images/arrow-left-icon-hover.png);
      }
      .owl-next:hover::before {
        background: url(../images/arrow-right-icon-hover.png);
      }
    }
  }
  .search-holder-blog {
    margin: 80px 0;
    float: left;
    @include max-screen($m-screen) {
      display: none;
    }
  }
  .sidebar {
    @include max-screen($m-screen) {
      .redes, .mais-visitados {
        display: none;
      }
    }
  }
}

.search {
  .search-holder-blog {
    margin: 80px 0;
    float: left;
    @include max-screen($m-screen) {
      display: none;
    }
  }
  .sidebar {
    @include max-screen($m-screen) {
      .redes, .mais-visitados {
        display: none;
      }
    }
  }
}

.single {
  #comments {
    margin: 50px 0 80px;
    > a {
      background-color: #e1e1e1;
      color: #000;
      padding: 15px 15px 15px 25px;
      margin: 0 auto;
      width: 200px;
      display: block;
      text-align: center;
      font-weight: bold;
      -moz-border-radius: 3px;
      border-radius: 3px;
      text-transform: uppercase;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        border: 10px solid transparent;
        border-left-color: #fcff00;
        position: absolute;
        left: 15px;
        top: 50%;
        margin-top: -10px;
      }
      span {
        color: #666;
      }
    }
  }
  .more-posts {
    .content {
      margin: 50px 0 15px;
      float: left;
      position: relative;
    }
    .slide {
      display: block;
      float: left;
      width: 100%;
      background: #3f2957;
      position: relative;
      img {
        width: 100%;
        opacity: .5;
        float: left;
      }
      &:hover img {
        opacity: 1;
      }
      h4 {
        position: absolute;
        bottom: 0;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 40px;
        line-height: 20px;
        margin: 10px 0;
        &::before {
          content: '';
          display: inline-block;
          border: 5px solid transparent;
          border-left-color: #fcff00;
          position: absolute;
          top: 5px;
          left: 25px;
        }
      }
    }
    .controls {
      width: 60%;
      margin: 0 auto;
      .control-btn {
        top: -160px;
        &.next {
          float: right;
        }
      }
    }
  }
}

#wrap {
  float: right;
  position: relative;

  @include max-screen($m-screen){
    display: none;
  }
  input[type="text"] {
    width: 0px;
    height: 50px;
    display: inline-block;
    font-size: 15px;
    font-weight: 100;
    border: none;
    outline: none;
    color: #fff;
    text-transform: uppercase;
    padding-right: 48px;
    background-color: transparent;
    cursor: pointer;
    border: 2px solid #fcff00;
    -webkit-border-radius: 10em;
    -moz-border-radius: 10em;
    border-radius: 10em;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    &:focus {
      width: 350px;
      z-index: 1;
      border: 2px solid #fcff00;
      cursor: text;
      padding: 0 48px 0 30px;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
  }
  form::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: url(../images/blog/buscaw.png) no-repeat;
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: -1;
  }
  input[type="submit"] {
    width: 54px;
    height: 54px;
    line-height: 54px;
    font-size: 22px;
    border-radius: 50%;
    font-style: none;
    margin-left: 10px;
    -webkit-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    display: block;
    cursor: pointer;
    background: #000;
    &:focus:hover {
      background-color: red;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

#wrap-open {
  position: relative;
  display: none;
  width: 300px;
  margin: 0 auto 20px;

  @include max-screen($m-screen){
    display: block;
  }
  input[type="text"] {
    width: 300px;
    height: 50px;
    display: inline-block;
    font-size: 15px;
    font-weight: 100;
    outline: none;
    color: #fff;
    text-transform: uppercase;
    padding: 0 48px 0 10px;
    background-color: transparent;
    cursor: pointer;
    border: 2px solid #fcff00;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    &:focus {
      width: 350px;
      z-index: 1;
      border: 2px solid #fcff00;
      cursor: text;
      padding: 0 48px 0 30px;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
  }
  input[type="submit"] {
    width: 24px;
    height: 24px;
    line-height: 54px;
    font-size: 0px;
    border: none;
    display: inline-block;
    cursor: pointer;
    background: url(../images/blog/buscaw.png) no-repeat;
    position: absolute;
    top: 12px;
    right: 12px;
    &:hover {
      opacity: 0.8;
    }
  }
}

/* SEARCH */

.home-blog.search .logos {
  margin-bottom: 0px;
  padding-bottom: 70px;
}

.posts {
  &.search {
    padding-top: 0;
    .posts-content.list-content {
      .title-search {
        font: 26px 'Open Sans', sans-serif;
        color: #000;
        text-transform: uppercase;
        font-weight: 800;
        text-align: left;
        padding: 0 0 0 30px;
        position: relative;
        margin: 40px 0 50px;
        @include max-screen($xs-screen){
          font-size: 20px;
        }
        &::before {
          content: '';
          position: absolute;
          top: 52px;
          left: 10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-color: #fcff00 transparent transparent transparent;
        }
        &::after {
          content: '';
          width: 100%;
          height: 2px;
          background: #fcff00;
          position: absolute;
          top: 52px;
          left: 0;
        }
      }
      article {
        border: 3px solid #e1e1e1;
        overflow: hidden;
        width: 49%;
        display: inline-block;
        margin-top: 10px;
        @include max-screen($s-screen){
          width: 100%;
        }
      }
      .thumb, .more a {
        width: 100%;
      }
      .thumb img {
        margin: 0;
      }
      .header-post, .more, .tags {
        width: 100%;
        padding: 0 10px;
      }
      .more {
        margin-top: 60px;
        @include max-screen($xs-screen){
          margin-top: 90px;
        }
      }
      .tags {
        margin-top: -100px;
        @include max-screen($xs-screen){
          margin-top: -130px;
        }
      }
    }
  }
  .posts-content.list-content .ops {
    background-color: #3f2957;
    color: #FFF;
    padding: 40px 0;
    .container {
      padding: 0 30px;
      margin: 0 30px;
      @include max-screen($m-screen){
        margin: 0;
        width: 100%;
      }
    }
    h2 {
      font-size: 70px;
      margin-top: 10px;
    }
    .container {
      h3 {
        color: #f9ed43;
        display: block;
        clear: both;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 100;
        margin-bottom: 5px;
      }
      h4 {
        color: #8d6e97;
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 100;
        text-align: left;
        line-height: 35px;
        margin-top: 0;
      }
    }
    .tags {
      padding: 0 30px;
      margin: 0 30px;
      float: initial;
      width: auto;
      text-align: left;
      @include max-screen($m-screen){
        margin: 0;
        width: 100%;
      }
      h3 {
        text-transform: uppercase;
        position: relative;
        padding: 20px 0;
        margin: 20px 0 40px;
        &::before {
          content: '';
          position: absolute;
          top: 60px;
          left: 10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-color: #fcff00 transparent transparent transparent;
        }
        &::after {
          content: '';
          width: 100%;
          height: 2px;
          background: #fcff00;
          position: absolute;
          top: 60px;
          left: 0px;
        }
      }
    }
  }
}

// 404
.not-found {
  .posts-content {
    @include max-screen($m-screen) {
      h3.antigos, .scroll-down-btn {display: none;}
    }
  }
  .sidebar {
    @include max-screen($m-screen) {
      .redes {display: block;}
      .mais-visitados, .categorias {display: none;}
    }
  }
}