.cases-full-grid {
  width: 100%;
  display: block;
  font-size: 0;
  
  img {
    width: 100%;
    display: block;
  }
  
  .cases-full-grid__item {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    
    @include max-screen($m-screen) {
      width: 100%;
      display: block;
    }
    
    &--featured {
      width: 100%;
      display: block;
    }
  }
}