.portfolio-description-section {
  background: #000;
  overflow: hidden;
  font-size: 0;
  max-height: 0;
  transition: $ease-inout;
  
  &.open {
    max-height: 1000px;
    padding: 80px 0;
  } 
  
  h1 {
    font: 26px 'Open Sans', sans-serif;
    color: #fff;
    text-transform: uppercase;
    display: block;
    padding-bottom: 40px;
    border-bottom: 3px solid $highlight-color;
    font-weight: 800;
    position: relative;
    
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 13px 14px 0 14px;
      border-color: $highlight-color transparent transparent transparent;
      position: absolute;
      top: 100%;
      left: 30px;
    }
  }
  
  .col {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 25px;
  }

  p {
    width: 100%;
    font: 14px/27px 'Roboto', sans-serif;
    color: #fff;
    display: inline-block;
    vertical-align: top;  
    margin-top: 25px;  
  }
}