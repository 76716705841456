.cases-full-head {
  background: url('../images/bg-full-case.jpg') center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  font-size: 0;
  
  .cases-full-head__col,
  .cases-full-head__col--box {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    
    @include max-screen($m-screen) {
      width: 100%;
      margin-bottom: 30px;
      
      &.cases-full-head__col {
        display: none;
      }
    }
  }
  
  .cases-full-head__col--box {
    border: 2px solid $highlight-color;
    padding: 30px;
    
    h2 {
      width: 60%;
      text-transform: uppercase;
      font: 26px/36px 'Open Sans', sans-serif;
      color: #000;
      display: block;
      font-weight: 900;
      margin: 0;
      margin-bottom: 30px;
    }
    
    p {
      font: 14px/28px 'Roboto', sans-serif;
      color: #000;
      display: block;
      font-weight: 300;
    }
  }
  
  .container{
    position: relative;
    padding-bottom: 200px;
  }
  
  h1 {
    font: 65px 'Open Sans', sans-serif;
    color: #fff;
    display: block;
    
  }
}